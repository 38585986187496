import { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import Grid from '@mui/material/Grid'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'

// Functional
import { createCarrier, editCarrier } from './CarrierManager.service'
import { analyticsEvent } from 'global/helpers/analytics'
import { sleep } from 'global/helpers/sleep'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'

// Types
import { CarrierData } from './CarrierManager.type'

interface CreateOrEditCarrierDialogPayload {
  carrier: CarrierData
}

interface CreateOrEditCarrierDialogProps {
  payload?: CreateOrEditCarrierDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface CreateOrEditCarrierFormValues {
  customerIdentifier: string
  name: string
  integration: 'NONE' | 'I_BROKER' | 'I_BROKER_V2'
  telematics: 'NONE' | 'S3PWEB' | 'NICPLACE'
  telematicsCredentials?: string
}

const CreateOrEditCarrierDialog = ({
  payload,
  open,
  onAction,
  onClose,
}: CreateOrEditCarrierDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, watch, reset, control, formState, setValue, setError } =
    useForm<CreateOrEditCarrierFormValues>()
  const watchedTelematics = watch('telematics')

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          customerIdentifier: payload?.carrier.customerIdentifier,
          name: payload?.carrier.name,
          integration: payload?.carrier.integration,
          telematics: payload?.carrier.telematics,
          telematicsCredentials: payload?.carrier.telematicsCredentials,
        })
      )
    }
  }, [open])

  useEffect(() => {
    if (watchedTelematics === undefined || watchedTelematics === 'NONE') {
      setValue('telematicsCredentials', undefined)
    }
  }, [watchedTelematics])

  const onSubmit: SubmitHandler<CreateOrEditCarrierFormValues> = async (data: any) => {
    try {
      if (payload?.carrier) {
        await editCarrier(payload.carrier.cid, data)
      } else {
        await createCarrier(data)
      }
      // NOTE: avoid stale data
      await sleep(500)
      onAction()
      analyticsEvent(payload ? 'polestar_cs_carrier_edited' : 'polestar_cs_carrier_created')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <KNDialog
        open={open}
        onClose={onClose}
        closeLabel={t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={
          payload
            ? `${payload.carrier.name} — ${t('screens.cs.carrier_manager.card.actions.edit')}`
            : t('screens.cs.carrier_manager.card.actions.new')
        }
        actions={
          <>
            <KNLoadingButton
              data-test="update-button"
              type="submit"
              color="primary"
              variant="contained"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {payload
                ? t('screens.cs.carrier_manager.add_edit_carrier.update')
                : t('screens.cs.carrier_manager.add_edit_carrier.create')}
            </KNLoadingButton>
          </>
        }
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        <Grid container spacing={2} mb={2}>
          <Grid data-test="edit-dialog-carrier-name" item xs={12}>
            <KNFormText
              name="name"
              label={t('screens.cs.carrier_manager.add_edit_carrier.name')}
              control={control}
              rules={{
                required: t('form.validation.required'),
              }}
              readonly
            />
          </Grid>
          <Grid data-test="edit-dialog-customer-identifier" item xs={12}>
            <KNFormText
              name="customerIdentifier"
              label={t('screens.cs.carrier_manager.add_edit_carrier.customer_identifier')}
              control={control}
              rules={{
                required: t('form.validation.required'),
              }}
              readonly
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid data-test="edit-dialog-integration" item xs={12} sm={6}>
            <KNFormDropdown
              name="integration"
              label={t('screens.cs.carrier_manager.add_edit_carrier.integration')}
              control={control}
              options={['NONE', 'I_BROKER', 'I_BROKER_V2'].map((integration) => {
                return {
                  value: integration,
                  label: t(`screens.cs.carrier_manager.integrations.${integration}`),
                }
              })}
              rules={{
                required: t('form.validation.required'),
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid data-test="edit-dialog-telematics" item xs={12} sm={6}>
            <KNFormDropdown
              name="telematics"
              label={t('screens.cs.carrier_manager.add_edit_carrier.telematics')}
              control={control}
              options={['NONE', 'S3PWEB', 'NICPLACE'].map((telematics) => {
                return {
                  value: telematics,
                  label: t(`screens.cs.carrier_manager.telematics.${telematics}`),
                }
              })}
              rules={{
                required: t('form.validation.required'),
              }}
            />
          </Grid>
          {watchedTelematics !== undefined && watchedTelematics !== 'NONE' && (
            <Grid item xs={12} sm={6}>
              <KNFormText
                name="telematicsCredentials"
                label={t('screens.cs.carrier_manager.add_edit_carrier.telematics_credentials')}
                control={control}
                rules={{
                  required: t('form.validation.required'),
                }}
              />
            </Grid>
          )}
        </Grid>
      </KNDialog>
    </KNForm>
  )
}

export default CreateOrEditCarrierDialog
